.App {
  text-align: center;
}

.collection_Virtual .p-virtualscroller{
  height: calc(100vh - 400px)!important;
}

.sales_Virtual .p-virtualscroller{
  height: calc(100vh - 170px)!important;
}

.table-container .p-virtualscroller{
  height: calc(100vh - 170px)!important;
}

.custom-datatable .p-virtualscroller{
  height: calc(60vh - 170px)!important;
  max-height: 200px!important;
}

.p-virtualscroller {
  height: calc(100vh - 220px)!important;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



p.opexheader {
  width: 100%;
  font-weight: 700;
  line-height: 0;
  margin: 12px 0px 0px 0px;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: none !important;
}
.card.card-progress-terretory {
  width: 50%;
  margin: 6px 1px;
}
/* Container for the dot to ensure proper positioning relative to the text */
.dotHighliter_main {
  position: relative;
  display: inline-block;
}

/* Styling for the dot */
.dotHighliter {
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #72ba7c;
  border-radius: 50%;
}
a.help_link {

  margin-right: 10px;
  max-width: fit-content;
  color: #fff;
  font-weight: 800;
  text-decoration: auto;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #ffffff;
  border-radius: 20px;
}
/* Extra Small Devices (Phones, less than 576px) */
@media (max-width: 575.98px) { 

  .progress_bar {
    font-size: 11px;
    width: 25%;
    position: absolute;
    top: 5px;
    text-align: center;
    left: 42em;
  }
  /* .progress-bar-active */
  /* .p-progressbar .p-progressbar-value {
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 10px;
  } */
}

/* Small Devices (Portrait tablets and large phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  
.progress_bar {
  font-size: 11px;
  width: 25%;
  position: absolute;
  top: 5px;
  text-align: center;
  left: 42em;
}

/* .p-progressbar .p-progressbar-value {
padding: 0 12px;
display: flex;
align-items: center;
font-size: 10px;
} */
}

/* Medium Devices (Landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
 
.progress_bar {
  font-size: 11px;
  width: 25%;
  position: absolute;
  top: 5px;
  text-align: center;
  left: 34em;
}


/* .p-progressbar .p-progressbar-value {
padding: 0 12px;
display: flex;
align-items: center;
font-size: 10px;
}
} */
}
/* Large Devices (Desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  
.progress_bar {
  font-size: 11px;
  width: 25%;
  position: absolute;
  top: 5px;
  text-align: center;
  left: 42em;
}

/* .p-progressbar .p-progressbar-value {
padding: 0 12px;
display: flex;
align-items: center;
font-size: 10px;
} */
}

/* Extra Large Devices (Large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) { 

  .progress_bar {
    font-size: 11px;
    width:20%;
    position: absolute;
    top: 5px;
    text-align: center;
    left: 42em;
  }
  
  /* .p-progressbar .p-progressbar-value {
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 10px;
  } */
}

/* XXL Devices (Very large screens, 1400px and up) */
@media (min-width: 1400px) { 
  
.progress_bar {
  font-size: 11px;
  width: 25%;
  position: absolute;
  top: 5px;
  text-align: center;
  left: 50em;
}
/* .p-progressbar .p-progressbar-value {
padding: 0 12px;
display: flex;
align-items: center;
font-size: 10px;
} */
}
/* XXXL Devices (Very large screens, 1400px and up) */
@media (min-width: 1900px) { 
  
.progress_bar {
  font-size: 11px;
  width: 25%;
  position: absolute;
  top: 5px;
  text-align: center;
  left: 60em;
}
/* .p-progressbar .p-progressbar-value {
padding: 0 12px;
display: flex;
align-items: center;
font-size: 10px;
} */
}

.customerDropDown .p-multiselect-clear-icon
{
  font-size: 10px !important;
  height: 10px !important;
  width: 10px !important;
  /* margin-left: 50px; */
  text-align: center;
  margin-right: -16px;
  margin-top: -5px;
}

.customerDropDownPanel .p-component
{
  font-size: 12px !important;
}
.customerDropDownPanel .p-multiselect-items-wrapper::-webkit-scrollbar {
  width: 5px;
}

.customerDropDownPanel .p-multiselect-items-wrapper::-webkit-scrollbar-track {
  background: #c4c7c5;
}

.customerDropDownPanel .p-multiselect-items-wrapper::-webkit-scrollbar-thumb {
  background: #5c73c4;
  border-radius: 10px;
}